import React from 'react'
import cx from 'classnames'

export function Badge({
  className,
  innerClassName,
  backgroundColor,
  children
}: React.PropsWithChildren<{
  className?: string
  innerClassName?: string
  backgroundColor: string
}>) {
  return (
    <span
      className={cx(className, 'p-1 pr-4 rounded inline-flex items-center')}
      style={{ backgroundColor }}
    >
      <span
        className={cx(
          'whitespace-nowrap px-0.5 bg-white rounded border-r leading-none',
          innerClassName
        )}
      >
        {children}
      </span>
    </span>
  )
}
