import React from 'react'
import { printCamelCase } from '../utils/printCamelCase'
import { JIRAIcon } from './VendorIcons'

export function ListItemInner({
  label,
  children
}: React.PropsWithChildren<{ label: string }>) {
  return (
    <>
      <strong>{`${printCamelCase(label)}: `}</strong>
      <span className='ml-1'>{children}</span>
    </>
  )
}

export function Hyperlink({
  label,
  href,
  children,
  target = '_blank'
}: React.PropsWithChildren<{
  label: string
  href: string
  target?: '_blank' | false
}>) {
  const aProps: { target?: string; rel?: string } = {}
  if (target === '_blank') {
    aProps.target = target
    aProps.rel = 'noopener noreferrer'
  }
  return (
    <ListItemInner label={label}>
      <span>
        <a {...aProps} style={{ overflowWrap: 'anywhere' }} href={href}>
          {children}
        </a>
      </span>
    </ListItemInner>
  )
}

export function JIRAProjectLink({
  label,
  value
}: {
  label: string
  value: string
}) {
  return (
    <Hyperlink
      label={label}
      href={`https://toasttab.atlassian.net/jira/software/c/projects/${value}/issues`}
    >
      <span>
        <JIRAIcon className='inline mr-1' />
        {value}
      </span>
    </Hyperlink>
  )
}
