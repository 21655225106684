import { InMemoryCacheConfig } from '@apollo/client'

export const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    Query: {
      fields: {
        spaElevations: {
          merge: false
        }
        // TODO: Fix caching for serviceManifest... field
        //        serviceManifestByRepo: {
        //          keyFields: ['serviceName', 'repoName']
        //        }
      }
    },
    SpaElevation: {
      keyFields: ['group', 'environment', 'spaId']
    }
  }
}
