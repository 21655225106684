import React from 'react'
import { ExpectedServiceManifest } from '@local/types'
import { BaseCard } from '@toasttab/buffet-pui-card'
import { Loading } from './Loading'
import { useOverviewMetadata } from '../hooks/useOverviewMetadata'
import { useRouteMatch } from 'react-router-dom'
import { CommunicationSection } from './CommunicationSection'
import { IncidentImpactSection } from './IncidentImpactSection'
import { GeneralDetails } from './GeneralDetails'
import { LinkButton } from '@toasttab/buffet-pui-buttons'
import { generateGithubHref } from '../utils/generateGithubHref'
import { SERVICE_MANIFEST_FILENAME } from '../config'
import { ErrorView } from './ErrorView'

export const SectionMetadata: React.FC = () => {
  const {
    params: { repoName }
  } = useRouteMatch<{ repoName: string }>()

  const { loading, error, data } = useOverviewMetadata({ repoName })

  // TODO: Verify empty state when real data is flowing
  const empty = !loading && !error && data && !data.serviceManifestByRepo

  // Note: This breaks all type safety by casting any -> ExpectedServiceManifest
  // This is expected for now until strong typing is restored on the GraphQL schema side
  const parsedData: ExpectedServiceManifest = data?.serviceManifestByRepo
    .manifestJson as ExpectedServiceManifest

  const serviceManifestExists: boolean =
    (error?.graphQLErrors?.[0]?.extensions?.statusCode as number) !== 404
  const githubHref = generateGithubHref({
    repoName,
    fileExists: serviceManifestExists,
    filename: SERVICE_MANIFEST_FILENAME
  })

  return (
    <>
      <BaseCard>
        {loading ? (
          <Loading />
        ) : error ? (
          <ErrorView repoName={repoName} error={error} />
        ) : empty ? (
          <span>
            <p>Missing service manifest - Update your manifest file!</p>
            <p>
              Please see{' '}
              <a href='https://toasttab.atlassian.net/wiki/spaces/TSC/pages/3336208402/Service+Manifest+Adoption'>
                Service Manifest Adoption
              </a>
            </p>
          </span>
        ) : parsedData ? (
          <>
            <GeneralDetails serviceManifest={parsedData} />

            <hr className='my-4' />
            <CommunicationSection communication={parsedData.communication} />

            <hr className='my-4' />
            <IncidentImpactSection impact={parsedData.impact} />

            <hr className='my-4' />
            <p>
              <span role='img' aria-label='lightning bolt'>
                &#x26A1;
              </span>
              {' Powered by the '}
              <a href='https://github.com/toasttab/service-manifest'>
                Service Manifest
              </a>
              . Schema version v{parsedData.schemaVersion}
            </p>
          </>
        ) : (
          'An unknown error occured'
        )}
      </BaseCard>
      <div>
        <LinkButton target='_blank' href={githubHref}>
          {`${serviceManifestExists ? 'Edit' : 'Create'} Manifest on Github`}
        </LinkButton>
      </div>
    </>
  )
}
