import React from 'react'
import { OverviewCard } from './components/OverviewCard'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { AppShell } from './components/AppShell'

const Routes = () => {
  return (
    <Route exact path={['/:repoName/overview']}>
      <AppShell>
        <OverviewCard />
      </AppShell>
    </Route>
  )
}

const App = () => {
  return (
    <Router>
      <Switch>
        <Routes />
      </Switch>
    </Router>
  )
}

export default App
