import React from 'react'
import { ExpectedServiceManifest } from '@local/types'
import { ServiceTierBadge, ArtifactTypeBadge, ComplianceBadge } from './Badges'

export function GeneralDetails({
  serviceManifest
}: {
  serviceManifest: ExpectedServiceManifest
}) {
  return (
    <div>
      <div className='flex justify-between mb-4'>
        <h2 className='type-headline-2'>{serviceManifest.serviceName}</h2>
      </div>
      <div className='inline-grid grid-flow-col gap-1'>
        {serviceManifest.artifactType?.length &&
          serviceManifest.artifactType.map((artifactType) => (
            <ArtifactTypeBadge key={artifactType} artifactType={artifactType} />
          ))}
        {serviceManifest?.impact?.tier && (
          <ServiceTierBadge tier={serviceManifest.impact.tier} />
        )}
        {!!serviceManifest.compliance?.length &&
          serviceManifest.compliance.map((compliance) => (
            <ComplianceBadge compliance={compliance} />
          ))}
      </div>
      <p className='mt-2'>{serviceManifest.description}</p>
    </div>
  )
}
