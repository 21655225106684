import React from 'react'
import { ApolloError } from '@apollo/client'
import { GraphQLError } from 'graphql'

// This shouldn't really happen, if it does we should handle the error better.
const emptyGraphQLError = new GraphQLError(
  'An unhandled error occurred, please file a ticket'
)

export function ErrorView({
  repoName,
  error
}: {
  repoName: string
  error: ApolloError
}) {
  if (error.networkError) {
    return (
      <div>
        <p className='mb-4'>
          There was a network error while fetching the GraphQL service.
        </p>
        <p>{error.networkError.message}</p>
      </div>
    )
  }

  const primaryGraphQLError = error.graphQLErrors[0] || emptyGraphQLError
  const {
    message,
    extensions: {
      statusCode,
      code,
      exception: { stacktrace } = { stacktrace: '' }
    }
  } = primaryGraphQLError

  const isSpa = repoName.startsWith('spa-') || repoName.endsWith('-spa')
  return (
    <div>
      <p className='mb-4'>There was an error loading the service metadata:</p>
      {isSpa && code === KnownGraphQLErrorCodes.ManifestNotFound ? (
        <>
          <p>
            <strong>It looks like this project is a SPA!</strong> Service
            Manifests are not yet deployed for SPAs, the TAP Team is aware of
            this and working on it.
          </p>
          <p>
            Please ensure your Manifest file is up to date, but do not worry if
            the data is not shown here. See the{' '}
            <a href='https://toasttab.atlassian.net/wiki/spaces/TSC/pages/3336208402/Service+Manifest+Adoption#Other'>
              Service Manifest FAQ
            </a>{' '}
            for more details.
          </p>
        </>
      ) : (
        <>
          <p>
            [{statusCode} {code}] {message}
            <br />
            {prettyPrintErrorCode(code)}
            {code === KnownGraphQLErrorCodes.ManifestNotFound && (
              <p>
                {'Please see '}
                <a href='https://toasttab.atlassian.net/wiki/spaces/TSC/pages/3336208402/Service+Manifest+Adoption'>
                  Confluence: Service Manifest Adoption
                </a>
              </p>
            )}
          </p>
          <details className='mt-4 overflow-auto'>
            <summary>Stack Trace</summary>
            {stacktrace?.length &&
              stacktrace.map((line: string) => <pre>{line}</pre>)}
          </details>
        </>
      )}
    </div>
  )
}

enum KnownGraphQLErrorCodes {
  ManifestNotFound = 'MANIFEST_NOT_FOUND',
  ENotFound = 'ENOTFOUND',
  Unknown = 'UNKNOWN' // Unhandled
}

function prettyPrintErrorCode(code: KnownGraphQLErrorCodes | string): string {
  switch (code) {
    case KnownGraphQLErrorCodes.ManifestNotFound:
      return 'The manifest was not found in the manifest service, please check that your manifest is up to date'
    case KnownGraphQLErrorCodes.ENotFound:
      return 'The manifest service domain name could not be resolved. Are you connected to the VPN?'
    default:
      return 'An unknown error occured, please try again'
  }
}
