import { Maybe, Scalars } from './schemaTypes'

export enum ArtifactType {
  GraphqlService = 'GRAPHQL_SERVICE',
  GrpcService = 'GRPC_SERVICE',
  Infrastructure = 'INFRASTRUCTURE',
  Library = 'LIBRARY',
  Other = 'OTHER',
  RestService = 'REST_SERVICE',
  ScheduledTask = 'SCHEDULED_TASK',
  Spa = 'SPA',
  Worker = 'WORKER'
}

export type Communication = {
  __typename?: 'Communication';
  department?: Maybe<Array<Department>>;
  documentationIndexLink?: Maybe<Scalars['String']>;
  githubTeam?: Maybe<Scalars['String']>;
  jiraProjectKey?: Maybe<Scalars['String']>;
  slackAlertChannel?: Maybe<Scalars['String']>;
  slackSupportChannel?: Maybe<Scalars['String']>;
};

export enum Compliance {
  Pci = 'PCI',
  Sox = 'SOX'
}

export enum Department {
  Employee = 'EMPLOYEE',
  Fintech = 'FINTECH',
  Guest = 'GUEST',
  Hardware = 'HARDWARE',
  NewVentures = 'NEW_VENTURES',
  Other = 'OTHER',
  Platform = 'PLATFORM',
  Restaurant = 'RESTAURANT'
}

export type Impact = {
  __typename?: 'Impact';
  incidentImpact?: Maybe<IncidentImpact>;
  tier?: Maybe<Tier>;
};


export type IncidentImpact = {
  __typename?: 'IncidentImpact';
  closeShifts?: Maybe<Scalars['String']>;
  financialIntegrity?: Maybe<Scalars['String']>;
  fireOrders?: Maybe<Scalars['String']>;
  fulfillOrders?: Maybe<Scalars['String']>;
  takeOrders?: Maybe<Scalars['String']>;
  takePayments?: Maybe<Scalars['String']>;
};

export type ExpectedServiceManifest = {
  __typename?: 'ExpectedServiceManifest';
  schemaVersion?: Maybe<Scalars['String']>;
  artifactType?: Maybe<Array<ArtifactType>>;
  communication?: Maybe<Communication>;
  compliance?: Maybe<Array<Compliance>>;
  description?: Maybe<Scalars['String']>;
  impact?: Maybe<Impact>;
  repositoryUrl?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
};

export enum Tier {
  Tier_0 = 'TIER_0',
  Tier_1 = 'TIER_1',
  Tier_2 = 'TIER_2',
  Tier_3 = 'TIER_3'
}
