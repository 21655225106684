import { resolveRepositoryRootHref } from './resolveRepositoryRootHref'
import { createServiceInfoYamlTemplate } from './createServiceInfoYmlTemplate'

export function generateGithubHref({
  repoName,
  fileExists,
  filename: filenameAndPath
}: {
  repoName: string
  filename: string
  fileExists?: boolean
}) {
  const repositoryUrl = resolveRepositoryRootHref({ repoName })

  const tokens = filenameAndPath.split('/')
  const dirname = tokens.slice(0, tokens.length - 1).join('/')
  const filename = tokens[tokens.length - 1]

  return fileExists
    ? `${repositoryUrl}/edit/main/${filenameAndPath}`
    : `${repositoryUrl}/new/main/${dirname}?filename=${filename}&value=${encodeURIComponent(
        createServiceInfoYamlTemplate({ repositoryUrl })
      )}`
}
