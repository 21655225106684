type RepositoryRootHrefArguments = {
  githubUsername?: string
  repoName: string
}

export function resolveRepositoryRootHref({
  githubUsername = 'toasttab',
  repoName
}: RepositoryRootHrefArguments): string {
  return `https://github.com/${githubUsername}/${repoName}`
}
