import React from 'react'
import { SectionMetadata } from './SectionMetadata'

export function OverviewCard() {
  return (
    <div className='lg:max-w-[66%]'>
      <SectionMetadata />
    </div>
  )
}
