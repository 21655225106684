import React from 'react'
import { Maybe, Impact } from '@local/types'
import { ListItemInner } from './PropertyFormatters'

export function IncidentImpactSection({ impact }: { impact?: Maybe<Impact> }) {
  const empty =
    impact &&
    impact.incidentImpact &&
    Object.keys(impact.incidentImpact).length === 0
  return impact && impact.incidentImpact ? (
    <div>
      <h5 className='type-headline-5 font-bold'>Incident Impact</h5>
      {empty ? (
        'If this service is down, the "rule of six" operations can still proceed.'
      ) : (
        <>
          <p className='caption'>
            In an incident, the following capabilities are at risk
          </p>
          <div className='mt-2'>
            <ol>
              {Object.entries(impact.incidentImpact).map(([key, value]) =>
                !value ? null : (
                  <li key={key}>
                    <ListItemInner label={key}>{value}</ListItemInner>
                  </li>
                )
              )}
            </ol>
          </div>
        </>
      )}
    </div>
  ) : (
    <p>No incident impact data is available.</p>
  )
}
