import { useQuery, gql } from '@apollo/client'
import { ServiceManifestQuery } from '@local/types'

type UseOverviewMetadataArguments = {
  repoName: string
}

// Placeholder data, to be shaped as a QueryResult from Apollo Client
export function useOverviewMetadata({
  repoName
}: UseOverviewMetadataArguments) {
  return useQuery<ServiceManifestQuery>(
    gql`
      query ServiceManifest($repoName: String!) {
        serviceManifestByRepo(repoName: $repoName) {
          manifestJson
        }
      }
    `,
    {
      variables: {
        repoName
      }
    }
  )
}
