import React from 'react'
import { ArtifactType } from '@local/types'
import { Badge } from './Badge'

// randomly generated by https://coolors.co
const artifactTypeColorMapping: { [key in ArtifactType]: string } = {
  [ArtifactType.GraphqlService]: '#52414c', // eggplant
  [ArtifactType.GrpcService]: '#8377d1', // tropical indigo
  [ArtifactType.Infrastructure]: '#5b8c5a', // sea green
  [ArtifactType.Library]: '#cfd186', // citron
  [ArtifactType.Other]: '#e3655b', // bittersweet
  [ArtifactType.RestService]: '#ffa400', // orange (web)
  [ArtifactType.ScheduledTask]: '#009ffd', // celestial blue
  [ArtifactType.Spa]: '#2a2A72', // royal blue
  [ArtifactType.Worker]: '#3E6990' // lapis lazuli
}

function printArtifactType(artifactType: ArtifactType): string {
  return {
    [ArtifactType.GraphqlService]: 'GraphQL',
    [ArtifactType.GrpcService]: 'GRPC',
    [ArtifactType.Infrastructure]: 'Infrastructure',
    [ArtifactType.Library]: 'Library',
    [ArtifactType.Other]: 'Other',
    [ArtifactType.RestService]: 'REST',
    [ArtifactType.ScheduledTask]: 'Scheduled',
    [ArtifactType.Spa]: 'SPA',
    [ArtifactType.Worker]: 'Worker'
  }[artifactType]
}

export function ArtifactTypeBadge({
  className,
  innerClassName,
  artifactType
}: {
  className?: string
  innerClassName?: string
  artifactType: ArtifactType
}) {
  return (
    <Badge
      className={className}
      innerClassName={innerClassName}
      backgroundColor={artifactTypeColorMapping[artifactType]}
    >
      {printArtifactType(artifactType)}
    </Badge>
  )
}
