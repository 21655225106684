export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  JSONObject: any;
};

export type Elevations = {
  __typename?: 'Elevations';
  elevationPercent?: Maybe<Scalars['String']>;
  elevationVersion?: Maybe<Scalars['String']>;
  gaVersion: Scalars['String'];
};

export type ElevationsInput = {
  elevationPercent?: InputMaybe<Scalars['String']>;
  elevationVersion?: InputMaybe<Scalars['String']>;
  gaVersion: Scalars['String'];
};

export enum Environment {
  Preprod = 'preprod',
  Prod = 'prod',
  Sandbox = 'sandbox'
}

export type Mutation = {
  __typename?: 'Mutation';
  /** Create a SPA elevation on a group and environment */
  createSpaElevation: SpaElevation;
  /** Remove a SPA elevation from a group and environment */
  removeSpaElevation: RemovedSpaGroupElevation;
};


export type MutationCreateSpaElevationArgs = {
  elevation: SpaElevationInput;
  spaId: Scalars['ID'];
};


export type MutationRemoveSpaElevationArgs = {
  environment: Environment;
  group: Scalars['ID'];
  spaId: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /** Adhoc feature branch versions */
  adhocVersions: Array<Maybe<SpaVersion>>;
  serviceManifestByRepo: ServiceManifest;
  /** All of the SPA elevations in all SPA groups */
  spaElevations: Array<Maybe<SpaElevation>>;
  spaEvents: Array<Maybe<SpaEvent>>;
  /** All of the SPA groups available */
  spaGroups: Array<Maybe<Scalars['ID']>>;
  /** SPA release versions */
  spaVersions: Array<SpaVersion>;
};


export type QueryAdhocVersionsArgs = {
  spaId: Scalars['ID'];
};


export type QueryServiceManifestByRepoArgs = {
  repoName: Scalars['String'];
};


export type QuerySpaElevationsArgs = {
  spaId: Scalars['ID'];
};


export type QuerySpaEventsArgs = {
  maxAge?: InputMaybe<Scalars['String']>;
  maxCount?: InputMaybe<Scalars['Int']>;
  spaId: Scalars['ID'];
};


export type QuerySpaVersionsArgs = {
  environment?: InputMaybe<Environment>;
  spaId: Scalars['ID'];
};

export type RemovedSpaGroupElevation = {
  __typename?: 'RemovedSpaGroupElevation';
  environment: Environment;
  group: Scalars['ID'];
  spaId: Scalars['ID'];
};

export type ServiceManifest = {
  __typename?: 'ServiceManifest';
  manifestJson?: Maybe<Scalars['JSONObject']>;
};

/** A SPA elevation for a particular SPA group and environment */
export type SpaElevation = {
  __typename?: 'SpaElevation';
  elevations: Elevations;
  environment: Environment;
  group: Scalars['ID'];
  spaId: Scalars['ID'];
};

export type SpaElevationInput = {
  elevations: ElevationsInput;
  environment: Environment;
  group: Scalars['String'];
};

export type SpaEvent = {
  __typename?: 'SpaEvent';
  date: Scalars['String'];
  description: Scalars['String'];
  environment: Environment;
  spaId: Scalars['ID'];
  type: SpaEventType;
  user: Scalars['String'];
};

export enum SpaEventType {
  Create = 'CREATE',
  Delete = 'DELETE',
  Modify = 'MODIFY'
}

export type SpaVersion = {
  __typename?: 'SpaVersion';
  assets: Scalars['JSONObject'];
  branch?: Maybe<Scalars['String']>;
  commitAuthor?: Maybe<Scalars['String']>;
  commitDate?: Maybe<Scalars['String']>;
  commitMsg?: Maybe<Scalars['String']>;
  spaId: Scalars['String'];
  version: Scalars['String'];
};

export type ServiceManifestQueryVariables = Exact<{
  repoName: Scalars['String'];
}>;


export type ServiceManifestQuery = { __typename?: 'Query', serviceManifestByRepo: { __typename?: 'ServiceManifest', manifestJson?: any | null } };
