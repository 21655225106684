import React from 'react'
import { Compliance } from '@local/types'
import { Badge } from './Badge'

// randomly generated by https://coolors.co
const complianceColorMapping: { [key in Compliance]: string } = {
  [Compliance.Pci]: '#2C8C99', // blue (munsell)
  [Compliance.Sox]: '#D2D8B3' // beige
}

function printCompliance(compliance: Compliance): string {
  return `${compliance} Compliant`
}

export function ComplianceBadge({ compliance }: { compliance: Compliance }) {
  return (
    <Badge backgroundColor={complianceColorMapping[compliance]}>
      {printCompliance(compliance)}
    </Badge>
  )
}
