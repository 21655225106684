import React from 'react'
import { MerryGoRound } from '@toasttab/buffet-pui-loading-indicators'

// TODO: Consolidate this with Loading component from tdp-repo-list.
//       Consider making a tdp-common library for common code sharing.
export function Loading() {
  return (
    <div className='relative flex-grow'>
      <div className='pin-center'>
        <MerryGoRound />
      </div>
    </div>
  )
}
