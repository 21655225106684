import React from 'react'
import { Tier } from '@local/types'
import { Badge } from './Badge'

// randomly generated by https://coolors.co
const tierColorMapping: { [key in Tier]: string } = {
  [Tier.Tier_0]: '#f72c25',
  [Tier.Tier_1]: '#E3655B',
  [Tier.Tier_2]: '#f7b32b',
  [Tier.Tier_3]: '#a9e5bb'
}

function printTier(tier: Tier): string {
  return tier
    .replace('_', ' ')
    .toLowerCase()
    .replace(/^./, ($0) => $0.toUpperCase())
}

export function ServiceTierBadge({ tier }: { tier: Tier }) {
  return (
    <Badge backgroundColor={tierColorMapping[tier]}>{printTier(tier)}</Badge>
  )
}
