import React from 'react'
import { JIRAProjectLink, Hyperlink, ListItemInner } from './PropertyFormatters'
import { Maybe, Communication } from '@local/types'

function formatGithubTeamHref(
  githubTeam: Maybe<string> | undefined
): string | null {
  if (!githubTeam) {
    return null
  }

  const [org, team] = githubTeam?.split('/') || []
  return `https://github.com/orgs/${org.replace('@', '')}/teams/${team}`
}

export function CommunicationSection({
  communication
}: {
  communication?: Maybe<Communication>
}) {
  if (!communication) {
    return null
  }

  const githubHref = formatGithubTeamHref(communication.githubTeam)

  return (
    <div>
      <h5 className='type-headline-5 font-bold'>Communication</h5>
      <ul className='mt-2'>
        <li>
          <ListItemInner label='Departments'>
            {communication.department?.length
              ? communication.department.join(',')
              : 'Unknown'}
          </ListItemInner>
        </li>
        <li>
          <ListItemInner label='Github Team'>
            {githubHref ? (
              <a href={githubHref}>{communication.githubTeam}</a>
            ) : (
              'Unknown'
            )}
          </ListItemInner>
        </li>
        {communication.documentationIndexLink && (
          <li>
            <Hyperlink
              href={communication.documentationIndexLink}
              label='Documentation'
            >
              {communication.documentationIndexLink}
            </Hyperlink>
          </li>
        )}
        {communication.jiraProjectKey && (
          <li>
            <JIRAProjectLink
              label='JIRA'
              value={communication.jiraProjectKey}
            />
          </li>
        )}
      </ul>
    </div>
  )
}
