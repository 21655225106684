import React from 'react'
import JIRAIconSvg from '../images/jira-icon.svg'

export function JIRAIcon(props: React.SVGProps<SVGElement>) {
  return (
    <JIRAIconSvg
      width='1em'
      height='1em'
      style={{ backgroundColor: '#0052CC' }}
      viewBox='0 0 32 32'
      {...props}
    />
  )
}
